// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adress-container{
    cursor: pointer;
    opacity: .6;
    &:hover{
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/Checkout/checkout.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX;QACI,UAAU;IACd;AACJ","sourcesContent":[".adress-container{\n    cursor: pointer;\n    opacity: .6;\n    &:hover{\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
