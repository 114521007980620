// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    color: black;
    background-color: #f1f1f1;
  }
  .footer .links ul {
    list-style-type: none;
  }
  .footer .links li a {
    color: white;
    transition: color 0.2s;
  }
  .footer .links li a:hover {
    text-decoration: none;
    color: #4180CB;
  }
  .footer .about-company i {
    font-size: 25px;
  }
  .footer .about-company a {
    color: white;
    transition: color 0.2s;
  }
  .footer .about-company a:hover {
    color: #4180CB;
  }
  .footer .location i {
    font-size: 18px;
  }
  .footer .copyright p {
    color: black;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

.footer-bank-logos{
  display: flex;
  align-items: center;
   justify-content: space-between;
}


@media only screen and (max-width: 600px) {
  .footer-bank-logos{
    display: none;
  }
  .copyright{
    flex-direction: row;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,qBAAqB;EACvB;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,qBAAqB;IACrB,cAAc;EAChB;EACA;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,cAAc;EAChB;EACA;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,8CAA8C;EAChD;;AAEF;EACE,aAAa;EACb,mBAAmB;GAClB,8BAA8B;AACjC;;;AAGA;EACE;IACE,aAAa;EACf;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".footer {\n    color: black;\n    background-color: #f1f1f1;\n  }\n  .footer .links ul {\n    list-style-type: none;\n  }\n  .footer .links li a {\n    color: white;\n    transition: color 0.2s;\n  }\n  .footer .links li a:hover {\n    text-decoration: none;\n    color: #4180CB;\n  }\n  .footer .about-company i {\n    font-size: 25px;\n  }\n  .footer .about-company a {\n    color: white;\n    transition: color 0.2s;\n  }\n  .footer .about-company a:hover {\n    color: #4180CB;\n  }\n  .footer .location i {\n    font-size: 18px;\n  }\n  .footer .copyright p {\n    color: black;\n    border-top: 1px solid rgba(255, 255, 255, 0.1);\n  }\n\n.footer-bank-logos{\n  display: flex;\n  align-items: center;\n   justify-content: space-between;\n}\n\n\n@media only screen and (max-width: 600px) {\n  .footer-bank-logos{\n    display: none;\n  }\n  .copyright{\n    flex-direction: row;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
