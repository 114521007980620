// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-price {
    font-weight: bolder;
}

.product-card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    border-radius: 15px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

.btn-dark {
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 150px;
}

.product-detail {
    .nav-item {
        background-color: #9f9f9f;
        border-radius: 0px;

        .nav-link {
            color: white;
            border-radius: 0px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Products/product.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,wEAAwE;IACxE,kDAAkD;IAClD,mBAAmB;;IAEnB;QACI,4EAA4E;IAChF;AACJ;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI;QACI,yBAAyB;QACzB,kBAAkB;;QAElB;YACI,YAAY;YACZ,kBAAkB;QACtB;IACJ;AACJ","sourcesContent":[".card-price {\n    font-weight: bolder;\n}\n\n.product-card {\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);\n    border-radius: 15px;\n\n    &:hover {\n        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n    }\n}\n\n.btn-dark {\n    background-color: black;\n    color: white;\n    border-radius: 7px;\n    width: 150px;\n}\n\n.product-detail {\n    .nav-item {\n        background-color: #9f9f9f;\n        border-radius: 0px;\n\n        .nav-link {\n            color: white;\n            border-radius: 0px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
