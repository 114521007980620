// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopping-count{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: black;
    background-color: #fefefe;
    border-radius: 50%;
    padding-left: 5px;
    padding-right: 5px;
}
.fa-cart-shopping{
    position: relative;
    z-index: 10;
    padding: 10px 10px;
}
.shopping {
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Header/header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".shopping-count{\n    position: absolute;\n    top: 0;\n    right: 0;\n    font-size: 12px;\n    color: black;\n    background-color: #fefefe;\n    border-radius: 50%;\n    padding-left: 5px;\n    padding-right: 5px;\n}\n.fa-cart-shopping{\n    position: relative;\n    z-index: 10;\n    padding: 10px 10px;\n}\n.shopping {\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
