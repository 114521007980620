// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-caption {
    width: 100% !important;
    top: 20%;
    text-align: left !important;

    h3,
    h1 {
        color: black;
    }
    button{
        background-color: #717fe0;
        opacity: 1 !important;
        padding: 7px 20px;
        border-radius: 15px;
        &:hover{
            opacity: .7 !important;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/slider.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,QAAQ;IACR,2BAA2B;;IAE3B;;QAEI,YAAY;IAChB;IACA;QACI,yBAAyB;QACzB,qBAAqB;QACrB,iBAAiB;QACjB,mBAAmB;QACnB;YACI,sBAAsB;QAC1B;IACJ;AACJ","sourcesContent":[".carousel-caption {\n    width: 100% !important;\n    top: 20%;\n    text-align: left !important;\n\n    h3,\n    h1 {\n        color: black;\n    }\n    button{\n        background-color: #717fe0;\n        opacity: 1 !important;\n        padding: 7px 20px;\n        border-radius: 15px;\n        &:hover{\n            opacity: .7 !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
