// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-container{
    span{
      font-size: 18px;
      margin-top: 10px;
    }
    strong{
        font-size: 18px;
    }
    .product-price{
        color: orange;
        font-size: 26px;
        font-weight: 600;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/product.css"],"names":[],"mappings":"AAAA;IACI;MACE,eAAe;MACf,gBAAgB;IAClB;IACA;QACI,eAAe;IACnB;IACA;QACI,aAAa;QACb,eAAe;QACf,gBAAgB;QAChB,oBAAoB;QACpB,6BAA6B;IACjC;EACF","sourcesContent":[".product-container{\n    span{\n      font-size: 18px;\n      margin-top: 10px;\n    }\n    strong{\n        font-size: 18px;\n    }\n    .product-price{\n        color: orange;\n        font-size: 26px;\n        font-weight: 600;\n        padding-bottom: 10px;\n        border-bottom: 1px solid #ddd;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
