// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainbox {
    margin: auto;
    width: 220px !important;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 100px;
    justify-content: center;
    text-align: center;
}
.error-container{
    height: 500px;
    .cursor-link{
        cursor: pointer;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/404/error.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb;QACI,eAAe;IACnB;AACJ","sourcesContent":[".mainbox {\n    margin: auto;\n    width: 220px !important;\n    position: relative;\n    display: flex;\n    align-items: center;\n    font-size: 100px;\n    justify-content: center;\n    text-align: center;\n}\n.error-container{\n    height: 500px;\n    .cursor-link{\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
