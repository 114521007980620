// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basket-container {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Gölge efekti */
    .fa-minus-orange, .fa-plus-orange {
        color: orange;
    }
    .basket-button-container {
        padding: 10px;
    }

    .basket-buttons {
        border: 1px solid black;
        border-radius: 15px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;
    }

    i {
        padding: 5px;
    }

    i:hover {
        cursor: pointer;
        background-color: black;
        opacity: .2;
        border-radius: 50%;
    }
}

.basket-button-container {
    border: none !important; 
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/Orders/order.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C,EAAE,iBAAiB;IAC9D;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;;IAEA;QACI,uBAAuB;QACvB,mBAAmB;QACnB,iBAAiB;QACjB,aAAa;QACb,mBAAmB;QACnB,8BAA8B;QAC9B,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,uBAAuB;QACvB,WAAW;QACX,kBAAkB;IACtB;AACJ;;AAEA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".basket-container {\n    width: 100%;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Gölge efekti */\n    .fa-minus-orange, .fa-plus-orange {\n        color: orange;\n    }\n    .basket-button-container {\n        padding: 10px;\n    }\n\n    .basket-buttons {\n        border: 1px solid black;\n        border-radius: 15px;\n        padding: 5px 10px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        width: 100px;\n    }\n\n    i {\n        padding: 5px;\n    }\n\n    i:hover {\n        cursor: pointer;\n        background-color: black;\n        opacity: .2;\n        border-radius: 50%;\n    }\n}\n\n.basket-button-container {\n    border: none !important; \n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
