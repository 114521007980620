// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-header{
    h5{
        color: gray;
        opacity: 1;
        &:hover{
            opacity: .8;
            cursor: pointer;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Banners/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,UAAU;QACV;YACI,WAAW;YACX,eAAe;QACnB;IACJ;AACJ","sourcesContent":[".banner-header{\n    h5{\n        color: gray;\n        opacity: 1;\n        &:hover{\n            opacity: .8;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
